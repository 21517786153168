import React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import './index.scss'



// const CustomSpinner = () => {
//   return (
// <div class="center">
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
//   <div class="wave"></div>
// </div>
//   )
// }




const FullScreenLoader = ({ children, text }) => {
  if (!text) {
    text = "Getting there..."
  }
  return (
    <LoadingOverlay
      active={true}
      spinner
      text={text}
      className="full-screen-loader"
    >
      {children}
    </LoadingOverlay>
  )
}

export default FullScreenLoader
