export default interface Song{
    title:string;
    etag:string;
    videoId:string;
    channelTitle:string;
    channelId:string;
    imageUrl:string;
    docId?:string;
    likes:number;
    artistName:string;
}


const getArtistName= (artists:any) => {
    if(Array.isArray(artists)){
        return artists.map((artist: any) => artist['name']).join(', ');
    }
    return '';
}

export const getSongFromMap = (data:any) => {
    let song:any={}
    song['title']=data?.['name']
    song['etag']=data?.['uri']
    song['videoId']=data?.['id']
    song['artistName'] = getArtistName(data?.['artists'])
    song['channelTitle']=data?.['album']?.['name']
    song['imageUrl']=data?.['album']?.['images']?.[0]?.['url']
    song['channelId'] = data?.['album']?.['id']
    song['likes'] = 0;
    const songWithType:Song = song;
    return songWithType
}
export const instanceOfSong = (data:any):data is Song => {
    if(!data) return false
    return 'title' in data && 'etag' in data && 'videoId' in data && 'channelTitle' in data && 'channelId' in data && 'imageUrl' in data;
}