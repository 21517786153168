import React, { ChangeEvent, ChangeEventHandler, FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../components/header'
import { UserContextValue, useAuth } from '../../providers/userProvider'
import { AuthType } from '../../services/authService'
import { RoutesKeys } from '../../utils/routes'
import './index.scss';
import spotifyLogo from '../../assets/spotifyLogo.png';

const Register = () => {
    const [email, setEmail] = useState<string>()
    const navigate = useNavigate()
    const [password, setPassword] = useState<string>()
    const [privacyPolicy, setPrivacyPolicy] = useState<boolean>();
    
    const {authenticate, user}:UserContextValue = useAuth()
    const redirectToRouteIfAny = () => {
        navigate(RoutesKeys.SELECT_CLUB)
    }
    useEffect(() => {
        if(user){
            redirectToRouteIfAny()
        }
    },[user])

    const handleEmailChange:ChangeEventHandler<HTMLInputElement> = (event:ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }


    const handlePwChange:ChangeEventHandler<HTMLInputElement> = (event:ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleSubmit:FormEventHandler<HTMLFormElement> = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        authenticate(AuthType.Register, email, password, '')
    }
    return (
        <>
        <Header pageName='Register' />
        <div className='login-container container content'>
            <form className='signin-form' onSubmit={handleSubmit}>



{/* HERE WE SWITCHED THE INPUT FIELDS FOR NEW ONES */}
<div className="input-group">
                        <input className="input" required type="email" name="email" id='email' autoComplete="off" value={email} onChange={handleEmailChange}/>
                        <label className="user-label" htmlFor='email'>Email</label>
                    </div>

                    <div className="input-group">
                        <input className="input" required type="password" name="password" id='password' autoComplete="off" value={password} onChange={handlePwChange}/>
                        <label className="user-label" htmlFor='password'>Password</label>
                    </div>


                {/* <label className='label' htmlFor='email'>Email</label>
                <input type='email' placeholder='Email' name='email' id='email' value={email} onChange={handleEmailChange} required />
                <label className='label' htmlFor='password'>Password</label>
                <input type='password' placeholder='Password' name='password' id='password' value={password} onChange={handlePwChange} required /> */}


                <div className='checkbox-label'>
                    <input type={'checkbox'} checked={privacyPolicy} onChange={(e)=>setPrivacyPolicy(e.target.checked)} id='privacy' name='privacy'></input><label htmlFor='privacy'>I accept the processing of personal data according to the privacy policy.</label>
                </div>
                <button className='register_main_button' disabled={!privacyPolicy}>Sign Up</button>
                
            </form>
            <div className='login-line'>Already have an account? <span onClick={() => navigate(RoutesKeys.LOGIN)}>Login.</span></div>

            <div className='footer-container'>
                    
                    <div className='div-1'>
                        <a href="https://youchoosemusic.it/cookie-policy" target="_blank" rel="noopener noreferrer">
                            <button className='privacy-button'>Privacy</button>
                        </a>
                    </div>

                    <div className='div-2'>
                        <img className='spotify-img' src={spotifyLogo} alt='' />
                    </div>

                    <div className='div-3'>
                        <label className='reserved-label'>All Rights Reserved 2022</label>
                    </div>
                    
                </div>


        </div>
    </>
    )
}

export default Register