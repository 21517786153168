import React, { FormEventHandler, useState } from 'react'
import { FaMinusCircle } from 'react-icons/fa'
import { uploadFile } from '../../../services/firebaseService'
import { useCommonComponents } from '../../../providers/commonComponentsProvider'
import { SnackbarTypes } from '../../../components/snackbar'

const AddClubForm = ({cancel, handleClubAdd}:{cancel:() => void, handleClubAdd:(email:string, name:string, bannerUrl:string, logoUrl:string) => void}) => {
    const [email, setEmail] = useState<string>('')
    const [logoError, setLogoError] = useState<string>('')
    const [bannerError, setBannerError] = useState<string>('')
    const [logo, setLogo] = useState<File>();
    const [banner, setBanner] = useState<File>()
    const [clubName, setClubName] = useState<string>('')
    const {showSnackbar, showLoader, hideLoader} = useCommonComponents()
    const handleSubmit:FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()
        showLoader(null)
        let bannerUrl = await uploadFile(banner, `${clubName}-${email}-banner-${banner?.name}`)
        let logoUrl = await uploadFile(logo, `${clubName}-${email}-logo-${logo?.name}`)
        hideLoader()
        if(!bannerUrl){
            showSnackbar({
                type: SnackbarTypes.ERROR,
                children:<span>Could not upload banner</span>,
            })
        }
        else if(!logoUrl){
            showSnackbar({
                type: SnackbarTypes.ERROR,
                children:<span>Could not upload logo</span>,
            })
        }
        if(bannerUrl && logoUrl){
            handleClubAdd(email, clubName, bannerUrl, logoUrl);
        }
        //handleClubAdd(email, clubName)
    }
    const processBanner = (files: FileList | null) => {
        if(!files) return;
        const file = files?.[0];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if(fileMb > 4) {
            setBannerError('File size must be less than 4Mb');
        } else{
            setBannerError('')
            checkFileResolution(file)
        }
    }
    const processLogo = (files: FileList | null) => {
        if(!files) return;
        const file = files?.[0];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if(fileMb > 1) {
            setLogoError('File size must be less than 1Mb');
        } else{
            setLogoError('')
            setLogo(file);
        }
    }
    const checkFileResolution = (file:File) => {
        let img = new Image()
        img.onload = function () {
            let resolution = img.width/img.height

            if(img.height < 890 || img.width < 1600 ||  resolution < 1.7 || resolution > 1.8){
               setBannerError('Image resolution should be 16:9 and width should be more than 1600px');
            } else{
                setBannerError('')
                setBanner(file);
            }
        };
        img.src = URL.createObjectURL(file);
    }
    const onCloseBanner = () => {
        setBanner(undefined);
    }
    const onCloseLogo = () => {
        setLogo(undefined);
    }
    const getBannerComponent = () => {
        if(banner){
            return <div className='preview-container'>
                    <label className='label'>Banner</label>
                    <span className='close-icon' onClick={onCloseBanner}><FaMinusCircle color='#19183c' size={20} /></span>
                    <img src={URL.createObjectURL(banner)} className='banner-preview preview' alt="Banner" />
                </div>
        } else{
            return (<>
                <label className={`banner-area ${bannerError ? 'has-error' : ''}`} htmlFor='image'>
                        <span>Upload banner (16:9)</span>
                    </label>
                    {bannerError ? <span className='error'>{bannerError}</span> : ''}
                    <input type={"file"} placeholder="Banner image" className='banner-input' name="image" id="image" required accept="image/*" onChange={(e) => processBanner(e.target.files)}/>
            </>);
        }
    }
    const getLogoComponent = () => {
        if(logo){
            return <div className='preview-container'>
                    <label className='label'>Logo</label>
                    <span className='close-icon' onClick={onCloseLogo}><FaMinusCircle color='#19183c' size={20} /></span>
                    <img src={URL.createObjectURL(logo)} className='logo-preview preview' alt="Logo" />
                </div>
        } else{
            return (
                <>
                    <label className={`banner-area ${logoError ? 'has-error' : ''}`} htmlFor='logo'>
                        <span>Upload logo</span>
                    </label>
                    {logoError ? <span className='error'>{logoError}</span> : ''}
                    <input type={"file"} placeholder="Banner image" className='banner-input' name="logo" id="logo" required accept="image/*" onChange={(e) => processLogo(e.target.files)}/>
                </>
            );
        }
    }

    return (
        <div className='add-club-form-container'>
            <form className='add-club-form' onSubmit={handleSubmit}>
                    {getBannerComponent()}
                    {getLogoComponent()}
                    <label className='label' htmlFor='club-name'>Club name</label>
                    <input type="text" placeholder='Club name' name="club-name" onChange={(e) => setClubName(e.target.value)} id="club-name" value={clubName} required />
                    <label className='label' htmlFor='email'>DJ Email</label>
                    <input type='email' placeholder='Email' name='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <div className='cta-container'><button onClick={cancel} className='outlined'>Cancel</button><button type='submit'>Add</button></div>
                </form>
        </div>
    )
}

export default AddClubForm