import React, { useState } from 'react'
import { FaChevronRight ,FaCaretRight, FaEdit, FaTrash, FaMinusCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Club, { instanceOfClub } from '../../entities/club'
import { useClub } from '../../providers/clubProvider'
import { useCommonComponents } from '../../providers/commonComponentsProvider'
import NetworkService from '../../services/networkService'
import { API_ENDPOINTS } from '../../utils/apiEndpoints'
import { RoutesKeys } from '../../utils/routes'
import { SnackbarTypes } from '../snackbar'
import './index.scss'
import { uploadFile } from '../../services/firebaseService'

const ClubItem = ({club, handleClubDelete, handleClubUpdate}:{club:Club | null, handleClubUpdate:(club:Club, clubId:string) => void, handleClubDelete:(clubId:string) => void}) => {
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [email, setEmail] = useState<string>(club?.email ?? '')
    const [name, setName] = useState<string>(club?.clubName ?? '')
    const {club:currentClub,setClub, updateClubSongs} = useClub()
    const [logoError, setLogoError] = useState<string>('')
    const [logoChanged, setLogoChanged] = useState<boolean>(false);
    const [bannerChanged, setBannerChanged] = useState<boolean>(false); 
    const [bannerError, setBannerError] = useState<string>('')
    const [logo, setLogo] = useState<File>();
    const [banner, setBanner] = useState<File>()
    const navigate = useNavigate()
    const {showPopup,hidePopup, showSnackbar, showLoader, hideLoader} = useCommonComponents()

    

    const processBanner = (files: FileList | null) => {
        if(!files) return;
        const file = files?.[0];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if(fileMb > 4) {
            setBannerError('File size must be less than 4Mb');
        } else{
            setBannerError('')
            checkFileResolution(file)
        }
    }
    const processLogo = (files: FileList | null) => {
        if(!files) return;
        const file = files?.[0];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if(fileMb > 1) {
            setLogoError('File size must be less than 1Mb');
        } else{
            setLogoError('')
            setLogo(file);
        }
    }
    const checkFileResolution = (file:File) => {
        let img = new Image()
        img.onload = function () {
            let resolution = img.width/img.height

            if(img.height < 890 || img.width < 1600 ||  resolution < 1.7 || resolution > 1.8){
               setBannerError('Image resolution should be 16:9 and width should be more than 1600px');
            } else{
                setBannerError('')
                setBanner(file);
            }
        };
        img.src = URL.createObjectURL(file);
    }
    const onCloseBanner = () => {
        setBannerChanged(true);
        setBanner(undefined);
    }
    const onCloseLogo = () => {
        setLogoChanged(true)
        setLogo(undefined);
    }
    const handleUpdate = async () => {
        try{
            showLoader(null)
            let bannerUrl, logoUrl;
            if(banner){
                bannerUrl = await uploadFile(banner, `${name}-${email}-banner-${banner?.name}`)
            } else{
                bannerUrl = club?.bannerUrl;
            }
            if(logo){
                logoUrl = await uploadFile(logo, `${name}-${email}-logo-${logo?.name}`)
            } else{
                logoUrl = club?.logoUrl;
            }
            let data = await NetworkService.post({
                url:API_ENDPOINTS.updateClub,
                data:{
                    club:{
                        clubId:club?.clubId,
                        clubName:name,
                        email:email,
                        bannerUrl,
                        logoUrl
                    },
                    oldEmail:club?.email
                }
            })
            hideLoader()
            if(instanceOfClub(data)){
                setIsEditable(false)
                handleClubUpdate(data, club?.clubId ?? '')
            } else{
                console.error(data)
                showSnackbar({
                    children:<span>Could not update club.</span>,
                    type:SnackbarTypes.ERROR
                })
            }
        } catch(err){
            hideLoader()
            console.error(err)
            showSnackbar({
                children:<span>Could not update club.</span>,
                type:SnackbarTypes.ERROR
            })
        }
    }
    const cancelEdit = () => {
        setEmail(club?.email ?? '')
        setName(club?.clubName ?? '')
        setBanner(undefined)
        setLogo(undefined)
        setBannerChanged(false)
        setLogoChanged(false)
        setIsEditable(false)
    }
    const getCtas = () => {
        if(isEditable){
            return (
                <div className='club-info-ctas'>
                    <button type='button' className='outlined' onClick={cancelEdit}>Cancel</button>
                    <button type='submit' className='primary' onClick={handleUpdate}>Update</button>
                </div>
            )
        }
        return null
    }
    const deleteClub = async () => {
        try{
            hidePopup()
            showLoader({
                text:'Deleting...',
                children:null
            })
            let data = await NetworkService.post({
                url:API_ENDPOINTS.deleteClub,
                data:{
                    clubId:club?.clubId,
                    email:club?.email
                }
            })
            hideLoader()
            if(data && data['error']){
                console.error(data)
                showSnackbar({
                    children:<span>Could not update club.</span>,
                    type:SnackbarTypes.ERROR
                })
            } else{
                hideLoader()
                showSnackbar({
                    children:<span>Club deleted successfully</span>,
                    type:SnackbarTypes.SUCCESS
                })
                handleClubDelete(club?.clubId ?? '')
            }
        } catch(err){
            console.error(err)
            showSnackbar({
                children:<span>Could not delete club.</span>,
                type:SnackbarTypes.ERROR
            })
        }
    }
    const getPopupContent = () => {
        return (
            <div className='deletion-content'>
                <p>Are you sure you want to delete the club?</p>
                <div className='ctas'>
                    <button type='button' className='outlined' onClick={hidePopup}>Cancel</button>
                    <button type='submit' className='primary' onClick={deleteClub}>Yes</button>
                </div>
            </div>
        )
    }
    const handleDelete = () => {
        showPopup({
            children:getPopupContent()
        })
    }
    const handleSongsPageClick = () => {
        if(club){
            setClub(club)
            if(currentClub?.clubId !== club.clubId){
                updateClubSongs([])
            }
            navigate(RoutesKeys.CLUB_SONG_LIST)
        }
    }

    const getBannerComponent = () => {
        if(banner || (club?.bannerUrl && !bannerChanged)){
            return <div className='preview-container'>
                    <label className='label'>Banner:</label>
                    <span className='close-icon' onClick={onCloseBanner}><FaMinusCircle color='#19183c' size={20} /></span>
                    <img src={banner ? URL.createObjectURL(banner) : club?.bannerUrl} className='banner-preview preview' alt="Banner" />
                </div>
        } else{
            return (<>
                <label className={`banner-area ${bannerError ? 'has-error' : ''}`} htmlFor='image'>
                        <span>Upload banner (16:9)</span>
                    </label>
                    <input type={"file"} placeholder="Banner image" className='banner-input' name="image" id="image" required accept="image/*" onChange={(e) => processBanner(e.target.files)}/>
                    {bannerError ? <span className='error'>{bannerError}</span> : ''}

            </>);
        }
    }
    const getLogoComponent = () => {
        if(logo || (club?.logoUrl && !logoChanged)){
            return <div className='preview-container'>
                    <label className='label'>Logo:</label>
                    <span className='close-icon' onClick={onCloseLogo}><FaMinusCircle color='#19183c' size={20} /></span>
                    <img src={logo ? URL.createObjectURL(logo) : club?.logoUrl} className='logo-preview preview' alt="Logo" />
                </div>
        } else{
            return (
                <>
                    <label className={`banner-area ${logoError ? 'has-error' : ''}`} htmlFor='logo'>
                        <span>Upload logo</span>
                    </label>
                    <input type={"file"} placeholder="Banner image" className='banner-input' name="logo" id="logo" required accept="image/*" onChange={(e) => processLogo(e.target.files)}/>
                    {logoError ? <span className='error'>{logoError}</span> : ''}
                </>
            );
        }
    }

    if(!club) return null
    return (
        <div className='club-item' style={isEditable ? {} : {backgroundImage: `url(${club.bannerUrl})`} }>
            {club.bannerUrl && <div className='club-item-overlay'></div>}
            <div className='club-info'>
                <div className='club-info-item'>
                    <label>Name:</label>
                    {isEditable ? <input type="text" required value={name} onChange={(e) => setName(e.target.value)} /> : <span className='club-info-value'>{name}</span>}
                </div>
                <div className='club-info-item'>
                    <label>Email:</label>
                    {isEditable ? <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} /> : <span className='club-info-value'>{email}</span>}
                </div>
                {club.logoUrl && <div className='club-info-item'>
                    {!isEditable && <label>Logo:</label>}
                    {!isEditable && <img className='club-info-value preview' src={club.logoUrl} alt='Logo' />}
                </div>}
                {isEditable ? getLogoComponent() : ''}
                {isEditable ? getBannerComponent() : ''}
                {!isEditable ? <div className='club-songs-page' onClick={handleSongsPageClick}>Show Club songs <FaChevronRight color='red' size={20} /></div> : ''}
                {getCtas()}
            </div>
            {!isEditable && <div className='icons'>
                <span className='edit-icon icon' onClick={() => setIsEditable(true)}><FaEdit size={20} color="#19183c" /></span>
                <span className='delete-icon icon' onClick={handleDelete}><FaTrash size={20} color="#19183c" /></span>
            </div>}
        </div>
    )
}

export default ClubItem