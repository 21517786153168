import React, { ChangeEvent, ChangeEventHandler, FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthType } from '../../services/authService'
import './index.scss'
import { useAuth, UserContextValue } from '../../providers/userProvider'
import Header from '../../components/header'
import { RoutesKeys } from '../../utils/routes'
import { instanceOfUser, UserType } from '../../entities/user'
import { useClub } from '../../providers/clubProvider'
import { useLikedSongs } from '../../providers/likedSongsProvider'
import spotifyLogo from '../../assets/spotifyLogo.png';

  


const Login = () => {
    const {authenticate, user}:UserContextValue = useAuth()
    const location = useLocation(), navigate = useNavigate()
    const {initialiseLikedSongs} = useLikedSongs()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const {setClub, initialiseClubSongs} = useClub()
    const redirectToRouteIfAny = () => {
        const state:any = location.state || {}
        if(user?.user_type === UserType.USER){
            initialiseLikedSongs()
            navigate(RoutesKeys.SELECT_CLUB)
        } else if(user?.user_type === UserType.DJ && user.club){
            setClub(user.club)
            initialiseClubSongs()
            navigate(RoutesKeys.CLUB_SONG_LIST)
        }
        else if(user?.user_type === UserType.MODERATOR) {
            navigate(RoutesKeys.CLUBS_LIST)
        }
        else if(state['from'] && state['from'] !== '/'){
            navigate(state['from'])
        } else{
            navigate(RoutesKeys.ERROR_PAGE)
        }
    }
    useEffect(() => {
        console.log('login', user)
        if(user){
            redirectToRouteIfAny()
        }
    },[user])
    const signUpWithGoogle = () => {
        authenticate(AuthType.Google)
    }

    const handleEmailChange:ChangeEventHandler<HTMLInputElement> = (event:ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }


    const handlePwChange:ChangeEventHandler<HTMLInputElement> = (event:ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleSubmit:FormEventHandler<HTMLFormElement> = (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        authenticate(AuthType.Email, email, password)
    }
    const continueWithoutSigning = () => {
        authenticate(AuthType.None)
    }
    return (
        <>



        {/* <div id="page1" className='background'> */}

            <Header pageName='' />
            {/* <Header pageName='Login' /> */}


            {/* <div id="overlay"><h1>DRINK DANCE REPEAT</h1></div> */}




            <div className='login-container container content'>
                <form className='signin-form' onSubmit={handleSubmit}>


                    {/* <div className="input-group">
                        <input required type="text" name="text" autoComplete="off" className="input" />
                        <label className="user-label">First Name</label>
                    </div> */}



{/* HERE WE SWITCHED THE INPUT FIELDS FOR NEW ONES */}
{/* 02 SEPTEMBER FLETSCH 4 lines below*/}
                    {/*<div className="input-group">
                        <input className="input" required type="email" name="email" id='email' autoComplete="off" value={email} onChange={handleEmailChange}/>
                        <label className="user-label" htmlFor='email'>Email</label>
                    </div>*/}
{/* 02 SEPTEMBER FLETSCH 4 lines below*/}
                    {/*<div className="input-group">
                        <input className="input" required type="password" name="password" id='password' autoComplete="off" value={password} onChange={handlePwChange}/>
                        <label className="user-label" htmlFor='password'>Password</label>
                    </div>*/}


                    {/* <label className='label' htmlFor='email'>Email</label>
                    <input type='email' placeholder='Email' name='email' id='email' value={email} onChange={handleEmailChange} required /> */}
                    {/* <label className='label' htmlFor='password'>Password</label>
                    <input type='password' placeholder='Password' name='password' id='password' value={password} onChange={handlePwChange} required /> */}

                </form>
{/* 02 SEPTEMBER FLETSCH 6 lines below*/}
                {/*<button className='signin-button'>Sign-In</button>
                </form>
                <div className='register-line'>Don't have an account? <span onClick={() => navigate(RoutesKeys.REGISTER)}>Register!</span></div>
                <div className='or'>Or</div>
                <div className='or'>Or</div>*/}
                {/*<button onClick={continueWithoutSigning}className='continue-without-button'>Continue without Signing in</button>*/}
                <button onClick={continueWithoutSigning} className='continue-without-button btn' type="button">
                <strong>Choose<br />Your<br />Party</strong>
                <div id="container-stars">
                <div id="stars"></div>
                </div>
                <div id="glow">
                <div className="circle"></div>
                <div className="circle"></div>
                </div>
                </button>
                <button onClick={signUpWithGoogle} className='google-signup-button'>Sign-Up with Google</button>


                

{/* Karlo - Privacy Button */}
                <div className='footer-container'>
                    
                    <div className='div-1'>
                        <a href="https://youchoosemusic.it/cookie-policy" target="_blank" rel="noopener noreferrer">
                            <button className='privacy-button'>Privacy</button>
                        </a>
                    </div>

                    <div className='div-2'>
                        <img className='spotify-img' src={spotifyLogo} alt='' />
                    </div>

                    <div className='div-3'>
                        <label className='reserved-label'>All Rights Reserved 2022</label>
                    </div>
                    
                </div>

                <div id="hey"><div id="layer-up"></div></div>
<div id="layer-0">
	
	<div id="layer-1">
		<div id="layer-2">
			<div id="lines">
				<div id="layer-corner"></div>
			</div>
		</div>
	</div>
</div>

<div id="mtnZZZ"></div>

                
            </div>

{/* </div> */}

        </>
    )
}

export default Login